&--umg {
  background-color: $umg-brand-black;
}
&--umg-background {
  background-color: $umg-brand-black;
}
&--umg-cs1 {
  background: url('../../../../../public/slide-assets/umg/case-studies/universal-pic.jpg');
}
&--umg-cs2 {
  background-image: url('../../../../../public/slide-assets/umg/case-studies/universal-pic.jpg'), url('../../../../../public/slide-assets/umg/case-studies/umg-logo-left.png');
  background-size: cover, auto 900px !important;
  background-position-x: 1040%, right;
  background-position-y: center;
}
&--umg-cs3 {
  background-image: url('../../../../../public/slide-assets/umg/case-studies/umg-logo-right.png'),url('../../../../../public/slide-assets/umg/case-studies/def-jam-logo-left.png');
  background-size: auto 900px, auto 700px !important;
  background-position-x: left, right;
  background-position-y: center, top;
}
&--umg-cs4 {
  background-image: url('../../../../../public/slide-assets/umg/case-studies/def-jam-logo-right.png'), url('../../../../../public/slide-assets/umg/case-studies/carrie-left.png');
  background-size: auto 700px, auto 100% !important;
  background-position-x: left, right;
  background-position-y: top, center;
}
&--umg-cs5 {
  background-image: url('../../../../../public/slide-assets/umg/case-studies/carrie-right.png');
  background-size: auto 100% !important;
  background-position-x: left;
}