&--landing {
  #{ $self }__container {
    background: url('../../../../public/slide-assets/landing/landing_bg.png'), $light-grey;
    background-repeat: no-repeat;
    background-position: right -220px center;
    background-size: auto 100%;
    @include breakpoint($tablet-lg) {
      background-position-x: 30%;
      background-size: cover;
    }
    @include breakpoint-landscape($tablet-lg-landscape) {
      background-position: right -420px center;
      background-size: cover;
    }
    @include breakpoint($phone-xl) {
      background-position-x: 30%;
      .slide-grid__content {
        margin-bottom: 0px !important;
      }
    }
  }
}