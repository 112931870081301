.page-navigation {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 12px;
  top: 50%;
  z-index: 2;
  &__horizontal {
    right: 31.4%;
    bottom: 122px;
    top: unset;
    flex-direction: row;
    @include breakpoint-height(855px) {
      right: 23%;
    }
    @include breakpoint($tablet-lg) {
      flex-direction: row;
      justify-content: center;
      bottom: calc(100vh - 120px);
      right: 0;
      width: 100%;
      &__icon { 
        margin: 0 7px;
        padding: 5px;
      }
    }
  }
  &__icon {
    width: 28px;
    height: 28px;
    margin: 6.5px 0;
    cursor: pointer;
  }
  @include breakpoint($tablet-lg + 1px) {
    flex-direction: row;
    justify-content: center;
    top: calc(100% - 60px);
    right: 0;
    width: 100%;
    &__icon { 
      margin: 0 7px;
    }
  }
  @include breakpoint($tablet-lg) {
    flex-direction: row;
    justify-content: center;
    bottom: calc(100vh - 120px);
    right: 0;
    width: 100%;
    &__icon { 
      margin: 0 7px;
      padding: 5px;
    }
  }
}