// colors
$light-black: #333333;
$light-black-2: #252525;
$light-black-3: #0E0E0E;
$off-white: #EAEAEA;
$light-grey: #dcdcdc;
$dark-grey: #1B1B1B;
$mid-grey: rgba(0,0,0,0.15);
// brand colors
$halo-brand-red: #EA3745;
$halo-brand-bg: #242525;
$halo-brand-layer: #1b1b1b4d;

$mercer-brand-light-green: #d8f1ec;
$mercer-brand-green: #B9E5DD;
$mercer-brand-purple: #653C9A;

$fiba-brand-light-blue: #121288;
$fiba-brand-blue: #0e0e52;
$fiba-brand-red: #F11D01;

$umg-brand-blue: #34B4E0;
$umg-brand-black: #171717;

$atmos-brand-blue: #0133A1;
$atmos-brand-white: #ffffff;


// fonts 
$header-1: 'GothamBlack';
$header-2: 'GothamBold';
$header-3: 'Gotham';

// breakpoints
// phone
$phone-sm: 320px;
$phone-sm-landscape: 568px;
$phone-sm-portrait: 675px;
$phone-md-portrait: 800px;
$phone-lg-portrait: 875px;
$phone-md: 375px;
$phone-lg: 414px;
$phone-xl: 575px;
$phone-lg-landscape: 736px;
$phone-xl-landscape: 812px;
// tablet
$tablet-sm: 768px;
$tablet-sm-landscape: 1024px;
$tablet-md: 992px;
$tablet-lg: 1024px;
$tablet-lg-landscape: 1366px;
// desktop
$desktop-sm: 1366px;
$desktop-md: 1440px;
$desktop-md-1: 1540px;
$desktop-lg: 1920px;
$desktop-xl: 2460px;
$desktop-xxl: 2560px;
$desktop-xxxl: 3840px;

