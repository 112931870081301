&--atmos {
  #{ $self }__container {
    background: url('../../../../public/slide-assets/atmos/atmos_background_shoe.png'), $off-white;
    background-repeat: no-repeat;
    background-size: 86%;
    background-position-x: 30%;
    background-position-y: 20%;
    @include breakpoint($phone-xl) {
      height: calc( 100vh - 75px);
    }
    @include breakpoint($tablet-md) {
      background-image: url('../../../../public/slide-assets/atmos/background-shoe.jpg');
      background-position-x: 100%;
    }
    #{ $self }__column {
      &--left {
        .breadcrumb {
          &__chip {
            color: white;
            @include brand-bg($atmos-brand-blue);
          }
        }
      }
      &--right {
        .img-1 {
          content: url('../../../../public/slide-assets/atmos/atmos_digiline.jpg');
          top: 12%;
          left: 60%;
          width: 30%;
          height: auto;
          z-index: 2;
        }
        .img-2 {
          content: url('../../../../public/slide-assets/atmos/atmos_products.jpg');
          top: 27%;
          left: 52%;
          width: 22%;
          height: auto;
          z-index: 3;
        }
        .img-3 {
          content: url('../../../../public/slide-assets/atmos/atmos_tweets.jpg');
          top: 35%;
          left: 82%;
          width: 9%;
          height: auto;
          z-index: 3;
        }
        .img-4 {
          content: url('../../../../public/slide-assets/atmos/atmos_shipping.jpg');
          top: 50%;
          left: 71%;
          width: 9%;
          height: auto;
          z-index: 4;
        }
        .img-mobile {
          content: url('../../../../public/slide-assets/atmos/atmos-group.png');
          @include breakpoint-min($phone-xl + 1) {
            width: 90%;
            margin: 0 auto 3vh;
          }
        }
      }
    }
  }
}