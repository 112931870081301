.breadcrumb {
  display: flex;
  margin-left: 12px;
  &__chip {
    display: flex;
    font-size: 14px;
    font-family: $header-2;
    text-transform: uppercase;
    text-align: center;
    padding: 6px 12px;
    margin-right: 8px;
    transform: skew(-22deg);
    // remove this
    &--test {
      color: white;
      background-color: #34B4E0;
    }
    //
    @include breakpoint($phone-lg) {
      font-size: 10px;
    }
    &--inner {
      align-items: center;
      display: flex;
      line-height: 1.2;
      transform: skew(22deg);
    }
  }
}