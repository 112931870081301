.slide-wrapper {
  $self: &;
  background-color: white;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  &__container {
    background-color: white;
    height: calc(100vh - 112px);
    width: calc(100vw - 112px);
  }
  // include mixin for brand color changes
  &__brand {
    &--mercer {
      @include brand-bg($mercer-brand-light-green);
      #{ $self }__container {
        @include brand-bg($mercer-brand-green);
      }
    }
    &--fiba {
      @include brand-bg($fiba-brand-blue);
      #{ $self }__container {
        @include brand-bg($fiba-brand-light-blue);
      }
    }
  }
}