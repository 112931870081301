&--fiba {
  #{ $self }__container {
    background: url('../../../../public/slide-assets/fiba/country-outline.svg'), linear-gradient(123.14deg, #121288 7.36%, #14146D 65.97%);
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint($phone-xl) {
      height: calc( 100vh - 75px);
    }
    #{ $self }__column {
      &--left {
        #{ $self }__header {
          color: white;
        }
        .breadcrumb {
          &__chip {
            color: white;
            @include brand-bg($fiba-brand-red);
          }
        }
      }
      &--right {
        .img-1 {
          content: url('../../../../public/slide-assets/fiba/fiba_basketball_cropped.jpg');
          top: 12%;
          left: 46%;
          width: 30%;
          height: auto;
        }
        .img-2 {
          content: url('../../../../public/slide-assets/fiba/fiba_seating.png');
          top: 42%;
          left: 48%;
          width: 8%;
          height: auto;
        }
        .img-3 {
          content: url('../../../../public/slide-assets/fiba/fiba_events_cropped.jpg');
          top: 42%;
          left: 60%;
          width: 26%;
          height: auto;
          z-index: 1000;
        }
        .img-mobile {
          content: url('../../../../public/slide-assets/fiba/fiba-group.png');
          @include breakpoint-min($phone-xl + 1) {
            width: 90%;
            margin: 0 auto 3vh;
          }
        }
      }
    }
  }
}