html, 
body {
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin:0;
  padding:0;
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;
}

#root {
  touch-action: none;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding:0;
  margin:0;
}

.h-100 {
  height: 100%;
}