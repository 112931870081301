.case-study-grid {
  $self: &;
  background-color: transparent;
  &--clip-overlay {
    background-color: #34B4E0;
    clip-path: polygon(0 100%, 120% -368%, 120% 200%, 0% 100%);
    width: 100%;
    height: 100vh;
    @include breakpoint($tablet-md) {
      clip-path: polygon(0 0, 100% 9%, 100% 100%, 0% 100%);
    }
  }
  &__container {
    height: calc(100vh);
    display: grid;
    grid-gap: 0;
    grid-template-columns: 65% 35%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    clip-path: polygon(0 108%, 120% -365%, 120% 100%, 0% 100%);
    width: 100%;
    height: 100vh;
    @include breakpoint($tablet-md) {
      clip-path: polygon(0 1%, 100% 10%, 100% 100%, 0% 100%);
      grid-template-columns: 100%;
    }
    #{ $self }__column {
      &--left,
      &--right {
        display: flex;
        align-items: center;
      }
      &--left {
        justify-content: center;
        #{ $self }__content {
          width: 600px;
          @include breakpoint($tablet-md) {
            margin: 0 36px;
            justify-content: left;
          }
          @include breakpoint-min($desktop-lg + 1) {
            width: 700px;
          }
          #{ $self }__header {
            color: white;
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 72px;
            letter-spacing: -0.02em;
            margin-bottom: 24px;
            @include breakpoint($tablet-md) {
              font-size: 40px;
              line-height: 50px;
              letter-spacing: -0.02em;
              margin-bottom: 24px;
              margin-top: 36px;
              width: 60vw;
            }
          }
          #{ $self }__stats {
            margin-top: 48px;
            display: flex;
            @include breakpoint($tablet-md) {
              display: none;
            }
            &--left {
              margin-right: 92px;
            }
            &--boosted {
              color: #34B4E0;
              font-family: $header-1;
              font-style: normal;
              font-weight: 900;
              font-size: 38px;
              line-height: 24px;
              letter-spacing: -0.02em;
              margin-bottom: 12px;
            }
            &--thin {
              color: white;
              font-family:$header-1;
              font-style: normal;
              font-weight: 325;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.02em;
            }
          }
        }
      }
      &--right {
        justify-content: left;
        #{ $self }__content {
          width: 435px; 
          @include breakpoint($tablet-md) {
            margin: 0 36px;
            justify-content: left;
            position: relative;
            margin-bottom: 70px;
          }
          @media screen and (min-width: $tablet-md) and (max-width: $desktop-md-1) {
            width: 80%;
          }
          &--additional-copy-wrap{
            overflow-y: auto !important;
          }
          #{ $self }__header-sm {
            color: white;
            font-family: $header-2;
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 29px;
            letter-spacing: -0.02em;
            &--atmos {
              color: #000000
            }
            &--halo {
              color: $halo-brand-red
            }
            &--colored {
              color: #34B4E0;
            }
            &--colored-atmos {
              color: #0133A1;
            }
            &--secondary-color {
              color: #121279;
            }
          }
          #{ $self }__button {
            color: white;
            font-family: $header-2;
            font-style: normal;
            font-weight: 325;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.02em;
            background-color: black;
            padding: 2px 8px;
            border-radius: 60px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            &-icon {
              font-size: 8px;
              margin-right: 6px;
            }
          }
        }
      }
      // for both left and right columns
      #{ $self }__copy {
        color: white;
        font-family: $header-2;
        font-style: normal;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        margin-top: 24px;
        @include breakpoint($tablet-md) {
          font-family: $header-3;
        }
        &--additional {
          color: white;
          font-family: $header-3;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
        }
        &--atmos {
          color: #000000;
        }
      }
      #{ $self }__additional {
        &-more {
          min-height:  10vh;
        }
        &-less {
          height: 0;
        }
      }
    }
    &--side-case {
      width: 30vw;
      margin-left: 14vw;
      margin-top: 25vh;
      z-index: 1;
      @include breakpoint($tablet-md) {
        width: 100vw;
        margin: 43vh 10vw 0;
      }
    }
    // container and column modifiers
    &--content-left {
      #{ $self }__column--left {
        justify-content: right;
        position: relative;
        @include breakpoint($desktop-md) {
          width: 85vw;
        }
        @include breakpoint-min($desktop-lg) {
          width: 70vw;
        }
        @include breakpoint-min($desktop-md + 1) {
          width: 79vw;
        }
        @include breakpoint-min(desktop-sm) {
          width: 75vw;
        }
        @include breakpoint($tablet-lg) {
          width: 100vw;
          justify-content: center;
        }
      }
    }
    &--content-right {
      clip-path: none;
      #{ $self }--clip-overlay {
        clip-path: none;
      }
    }
    &--desktop {
      @include breakpoint($tablet-md) {
        display: none;
      }
    }
    &--mobile {
      @include breakpoint-min($tablet-md + 1) {
        display: none;
      }
    }
    &--mobile-image {
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      opacity: 1;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      @include breakpoint-min($tablet-sm) {
        width: 70%;
        left: auto;
      }
    }
    // case study modifiers
    @import 'slides/case-studies/umg-case-study.scss';
    @import 'slides/case-studies/atmos-case-study.scss';
    @import 'slides/case-studies/halo-case-study.scss';
    @import 'slides/case-studies/fiba-case-study.scss';
    @import 'slides/case-studies/side-case-study.scss';
  }
}
 