&--halo {
  background-color: $light-black-2;
  height: calc(100% - 112px);
  @include breakpoint($tablet-md) {
    height: calc(100% - 74px);
  }
  #{ $self }__container {
    z-index: 10;
    @include breakpoint($phone-xl) {
      height: calc( 100vh - 75px) !important;
    }
    #{ $self }__column {
      &--left {
        #{ $self }__header {
          color: white;
        }
        .breadcrumb {
          &__chip {
            color: $halo-brand-red;
            @include brand-bg($dark-grey);
          }
        }
      }
      &--right {
        .img-1 {
          content: url('../../../../public/slide-assets/halo/halo-digiline.png');
          top: 12%;
          left: 57%;
          width: 33%;
          height: auto;
          z-index: 4;
        }
        .img-2 {
          content: url('../../../../public/slide-assets/halo/halo-ticketsocket.jpg');
          top: 21%;
          left: 44%;
          width: 12%;
          height: auto;
          z-index: 4;
        }
        .img-3 {
          content: url('../../../../public/slide-assets/halo/halo-seating.jpg');
          top: 33%;
          left: 64%;
          width: 28%;
          height: auto;
          z-index: 4;
        }
        .img-4 {
          content: url('../../../../public/slide-assets/halo/halo-scrapt.jpg');
          top: 36%;
          left: 54%;
          width: 12%;
          height: auto;
          z-index: 4;
        }
        .img-mobile {
          content: url('../../../../public/slide-assets/halo/halo-group.png');
          @include breakpoint-min($phone-xl + 1) {
            width: 90%;
            margin: 0 auto 3vh;
          }
        }
      }
    }
  }
}