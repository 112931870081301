// branded color change mixins
@mixin brand-bg($bg) {
  background: $bg;
}

@mixin brand-color($clr) {
  color: $clr;
}

// breakpoint mixins
@mixin breakpoint($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin breakpoint-min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin breakpoint-landscape($size) {
  @media (max-width: $size) and (orientation: landscape) {
    @content;
  }
}

@mixin breakpoint-height($size) {
  @media (max-height: $size) {
    @content;
  }
}

@mixin rotate($value) {
  -ms-transform: rotate($value);
  -webkit-transform: rotate($value);
  -moz-transform: rotate($value);
  transform: rotate($value);
}