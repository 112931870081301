.animated-button {
  position: relative;
  top: 2rem;
  height: 3.5rem;
  background: transparent;
  color: white;
  border: 1px solid white;
  font-weight: bolder;
  font-size: medium;
  overflow: hidden;
  @include breakpoint($phone-xl) {
    top: 1rem;
  }
  .fader {
    position: absolute;
    z-index: -1;
    left: -30px;
    top: 0;
    opacity: 0.15;
    display: block;
    width: 150%;
    height: 100%;
    background-color: white;
    transform: skewX(-30deg);
  }
  &--dark{
    color: black;
    border-color: black;
    .fader{
      opacity: 0.4;
    }
  }
  &.disabled {
    color: grey;
    border-color: grey !important;
    cursor: not-allowed;
  }
  @include breakpoint-min($tablet-lg + 1px) {
    width: 227px;
  }
  @include breakpoint($tablet-lg) {
    width: 100%;
  }
}