&--mercer {
  background: url('../../../../public/slide-assets/mercer/mercer-bg.png'), $mercer-brand-green;
  background-repeat: no-repeat;
  background-size: 84%;
  background-position-x: 100%;
  @include breakpoint($tablet-md) {
    background-image: url('../../../../public/slide-assets/mobile/mercer-mobile-bg.png');
    background-size: 100%;
    background-position-x: unset;
  }
  height: calc(100% - 76px);
  #{ $self }__container {
    @include breakpoint($tablet-md) {
      height: 100%;
    }
    #{ $self }__column {
      &--left {
        .breadcrumb {
          &__chip {
            color: white;
            @include brand-bg($mercer-brand-purple);
          }
        }
      }
      &--right {
        .img-1 {
          content: url('../../../../public/slide-assets/mercer/mercer-indigo.jpg');
          top: 36%;
          left: 50%;
          width: 30%;
          height: auto;
          z-index: 5;
        }
        .img-2 {
          content: url('../../../../public/slide-assets/mercer/mercer-upwork.jpg');
          top: 22%;
          left: 58%;
          width: 30%;
          height: auto;
          z-index: 4;
        }
        .img-3 {
          content: url('../../../../public/slide-assets/mercer/mercer-michaels.jpg');
          top: 8%;
          left: 66%;
          width: 30%;
          height: auto;
          z-index: 3;
        }
        .img-mobile {
          content: url('../../../../public/slide-assets/mobile/mercer-group.png');
          width: 90%;
          height: auto;
          left: 5%;
        }
      }
    }
  }
}
