
@font-face {
  font-family: 'Gotham';
  font-weight: normal;
  font-style: normal;
  src: url('../../../public/fonts/gothamlight.otf');
}

@font-face {
  font-family: 'GothamBMedium';
  font-weight: normal;
  font-style: normal;
  src: url('../../../public/fonts/gothammedium.otf');
}

@font-face {
  font-family: 'GothamBlack';
  font-weight: normal;
  font-style: normal;
  src: url('../../../public/fonts/gothamblackregular.ttf');
}

@font-face {
  font-family: 'gothambold';
  font-weight: bold;
  font-style: normal;
  src: url('../../../public/fonts/gothambold.otf');
}

h1, 
h2 {
  font-family: $header-1;
}

h3 {
  font-family: $header-3;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 3px;
}