.contact-text {
  $self: &;
  &__block {
    margin-top: 40px;
    @include breakpoint($tablet-md) {
      margin-top: 20px;
      margin-right: 15px;
      display: inline-grid;
    }
    #{ $self }__sub-header {
      color: $light-black-3;
      font-family: $header-2;
      font-style: normal;
      font-weight: 350;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.02em;
      margin-bottom: 4px;
      @include breakpoint($tablet-md) {
        font-size: 10px;
      }
    }
    #{ $self }__header {
      color: $light-black-3;
      font-family: $header-1;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      @include breakpoint($tablet-md) {
        font-size: 12px;
      }
    }
  }
}