&--contact-slide {
  #{ $self }__container {
    background: url('../../../../public/slide-assets/contact/contact.svg'), $light-grey;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: right top;
    flex-direction: column;
    @include breakpoint($tablet-md) {
      background-size: 246%;
      background-position-x: -6%;
      #{ $self }__column--right {
        padding-top: 0;
      }
    }
    @include breakpoint($tablet-lg) {
      #{ $self }__column--left {
        padding-top: 56px;
        #{ $self }__content {
          margin-bottom: 16px;
        }
      }
    }
    @include breakpoint($phone-xl) {
      #{ $self }__column--left {
        padding-top: 17px;
        padding-bottom: 2px;
      }
    }
    @include breakpoint($phone-md + .5px) {
      #{ $self }__column--left {
        padding-top: 32px;
        #{ $self }__content {
          margin-bottom: 10px;
          #{ $self }__header {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}