&--umg {
  #{ $self }__container {
    background-image: url('../../../../public/slide-assets/umg/umg_jb_1.jpg');
    background-repeat: no-repeat;
    background-position: center 10%;
    background-size: cover;
    @include breakpoint($phone-xl) {
      background-position-x: 40%;
      height: calc( 100vh - 75px);
    }
    #{ $self }__column {
      &--left {
        #{ $self }__header {
          color: white;
        }
        .breadcrumb {
          &__chip {
            color: black;
            @include brand-bg($umg-brand-blue);
          }
        }
      }
      &--right {
        .img-1 {
          content: url('../../../../public/slide-assets/umg/umg_ladygaga.jpg');
          top: 12%;
          left: 54%;
          width: 30%;
          height: auto;
        }
        .img-2 {
          content: url('../../../../public/slide-assets/umg/umg_mia.jpg');
          top: 30%;
          left: 62%;
          width: 30%;
          height: auto;
        }
        .img-3 {
          content: url('../../../../public/slide-assets/umg/umg_snoop.jpg');
          top: 50%;
          left: 45%;
          width: 28%;
          height: auto;
        }
        .img-4 {
          content: url('../../../../public/slide-assets/umg/umg_mgk.jpg');
          top: 41%;
          left: 79%;
          width: 7%;
          height: auto;
        }
        .img-5 {
          content: url('../../../../public/slide-assets/umg/umg_ag.jpg');
          top: 51%;
          left: 75%;
          width: 7%;
          height: auto;
        }
        .img-mobile {
          content: url('../../../../public/slide-assets/umg/umg-group.png');
          @include breakpoint-min($phone-xl + 1) {
            margin: 0 auto 3vh;
          }
        }
      }
    }
  }
}