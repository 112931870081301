.nav {
  $self: &;
  padding: 0 56px;
  height: 56px;
  display: flex;
  align-items: center;
  @include breakpoint($tablet-md) {
    padding: 0 18px;
  }
  &__alert {
    margin: auto;
    background-color: white;
    padding: 6px 22px;
    border: 1px solid rgb(241, 29, 1);
    font-family: GothamBlack;
    font-size: 12px;
    @include breakpoint($tablet-md) {
      margin: auto 1rem;
      font-size: 9px;
      padding: 6 18px;
    }
  }
  &__logo-container {
    z-index: 100;
    #{ $self }__logo {
      width: 104px;
      cursor: pointer;
    }
  }
  &__action-container {
    margin-left: auto;
    display: flex;
    z-index: 100;
    #{ $self }__items {
      font-size: 16px;
      font-family: $header-1;
      cursor: pointer;
      margin-right: 78px;
      &--underline {
        display: block;
        background-color: red;
        height: 5px;
        width: 70%
      }
    }
    #{ $self }__social-container {
      #{ $self }__social-link {
        margin-left: 16px;
        &:first-of-type {
          margin-left: 0;
        }
        #{ $self }__social-icon {
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    @include breakpoint($tablet-md){
      display: none;
    }
  }
  &__menu {
    top: 56px;
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    position: absolute;
    z-index: 1000;
    
    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px 18px 18px 18px;
      align-items: center;
      justify-content: center;
      .nav__social {
        &--link_container {
          margin-top: 2.5rem;
        }
        &--link {
          margin: 0 0.5rem;
        }
      }
    }

    @include breakpoint-min($tablet-md + 1px) {
      display: none !important;
    }

  }

  &__menu-container {
    float: right;
    margin-left: auto;
    margin-right: 5px;
    display: none;
    @include breakpoint($tablet-md){
      display: initial;
      z-index: 100;
    }
  }
}