.slide-grid {
  $self: &;
  margin: 0 52px;
  height: 100%;

  // import slide components
  @import 'slides/landing.scss';
  @import 'slides/umg.scss';
  @import 'slides/mercer.scss';
  @import 'slides/fiba.scss';
  @import 'slides/atmos.scss';
  @import 'slides/halo.scss';
  @import 'slides/contact-slide.scss';

  @include breakpoint($tablet-md) {
    margin: 0 18px;
  }
  &--overlay {
    background: black;
    height: 100%;
    width: 100vw;
    position: absolute;
    opacity: 0;
  }
  &__container {
    height: calc(100vh - 112px);
    display: grid;
    clip-path: inset(0);
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    background-color: transparent;
    @include breakpoint($tablet-lg + 1px) {
      display: flex;
      flex-direction: column-reverse;
      height: calc(100vh - 74px);
    }
    #{ $self }__column {
      background-color: transparent;
      &--left {
        padding: 0 0 0 52px;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        z-index: 10;
        @include breakpoint($tablet-lg) {
          height: auto;
          padding: 0 52px 52px;
        }
        @include breakpoint($phone-xl) {
          padding: 0px 18px 103px 18px;
        }
        #{ $self }__content {
          width: 430px;
          justify-self: center;
          margin-top: auto;
          margin-bottom: auto;
  
          // import breadcrumb component
          @import 'breadcrumb.scss';
          // import animated button component
          @import 'animated-button.scss';

          @include breakpoint-min($desktop-lg + .1px) {
            width: 65%;
          }
          @include breakpoint($desktop-lg) {
            width: 540px;
          }
          @include breakpoint($desktop-md) {
            width: 430px;
          }
          @include breakpoint($tablet-lg) {
            font-size: 34px;
            line-height: 37px;
            width: 100%;
            margin-bottom: 60px;
          }
          #{ $self }__header {
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 56px;
            letter-spacing: -0.02em;
            margin-bottom: 22px;
            @include breakpoint-min($desktop-lg + .1px) {
              font-size: 3.2vw;
              line-height: 3.2vw;
            }
            @include breakpoint($tablet-md) {
              font-size: 34px;
              line-height: 37px;
            }
            @include breakpoint($phone-xl) {
              font-size: 28px;
              line-height: 31px;
              margin-bottom: 18px;
            }
          }
          button {
            margin-top: 0;
          }
        }
        #{ $self }__logo-container {
          margin-top: 0;
          #{ $self }__logo {
            width: 100px;
            margin-bottom: 56px;
            align-self: flex-end;
            @include breakpoint-min($desktop-lg) {
              width: 8vw;
            }
            @include breakpoint($tablet-md) {
              width: 80px;
            }
          }
          @include breakpoint($tablet-md) {
            position: absolute;
            top: 74px;
            right: 44px;
          }
        }
      }
      &--right {
        z-index: 1000;
        display: flex;
        align-items: center;
        @include breakpoint($tablet-lg) {
          padding-top: 6vh;
          justify-content: center;
        }
        @include breakpoint($tablet-md) {
          padding-top: 0vh;
        }
        img {
          border-radius: 5px;
          filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
          position: absolute;
          // handles mobile images
          @include breakpoint($tablet-lg) {
            width: 100%;
            position: relative;
            &.img-1,
            &.img-2,
            &.img-3,
            &.img-4,
            &.img-5 {
              display: none;
            }
          }
          &.img-mobile {
            display: block;
            @include breakpoint-min($tablet-lg + 1px) {
              display: none
            }
            @include breakpoint-height($phone-lg-portrait) {
              margin-bottom: 18px;
            }
            @include breakpoint($phone-xl) {
              margin-bottom: 13px;
            }
          }
        }
      }
    }
  }
  #{ $self }__navigation {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 12px;
    top: 50%;
    &-icon {
      width: 28px;
      height: 28px;
      margin: 7px 0;
      cursor: pointer;
    }
    @include breakpoint($tablet-lg + 1px) {
      flex-direction: row;
      top: calc(100% - 60px);
      width: 100%;
      &-icon { 
        margin: 0 7px;
        &--left {
          margin-left: auto;
        }
        &--right {
          margin-right: auto;
        }
      }
    }
  }
}